import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { Link as ScrollLink, Element } from "react-scroll";
import styled from "styled-components";

import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import Masonry from "react-masonry-css";
import { device } from "../utils";
import ResponsiveEmbed from "react-responsive-embed";

const MasonryStyled = styled(Masonry)`
display: flex;
margin-left: -30px;
width: auto;

.masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}
.masonry-grid_column > div {
  margin-bottom: 30px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 15px;
  }
}
`

const TRA = () => {

    const breakpointColumnsObj = {
        default: 2,
        768: 1
    };

    return (
        <React.Fragment>
            <PageWrapper darkTheme>
                <Section hero>
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col lg="8">
                                <Box>
                                    <Title className="my-4">
                                        The Road Ahead
                                    </Title>
                                    <Text variant="p" css={` max-width: 750px; `} className="mb-3 mx-auto">
                                        Toyota Canada and Freestyle Canada have had a long-lasting partnership with one
                                        of the primary deliverables being a yearly four-part series
                                        highlighting the road to the competitive success of the Canadian Freestyle Ski Team athletes. With the
                                        2021-2022 season being an Olympic year, we tracked down four athletes from each
                                        discipline to talk about their road to the world stage.
                                    </Text>
                                </Box>
                            </Col>
                        </Row>

                        <div className="mt-lg-5">
                            <Container>
                                <Row>
                                    <Col lg="4" className="mb-4 mb-lg-0">
                                        <Text variant="tag">Brand</Text>
                                        <Title variant="cardLg" className="mt-3">
                                            Toyota Canada
                                        </Title>
                                    </Col>
                                    <Col lg="4" className="mb-4 mb-lg-0">
                                        <Text variant="tag">Role</Text>
                                        <Title variant="cardLg" className="mt-3">
                                            Producer, Videographer, Photographer
                                        </Title>
                                    </Col>
                                    <Col lg="4" className="d-flex justify-content-lg-end">
                                        <ScrollLink to="work" spy={true} smooth={true} offset={-50} duration={1000}>
                                            <Button arrowRight>See More</Button>
                                        </ScrollLink>
                                    </Col>
                                </Row>
                            </Container>
                        </div>

                        <Box pt={["4rem", null, null, "2rem"]}>
                            <StaticImage
                                src="../assets/image/work/tra-header.jpg"
                                alt="The Road Ahead Cover"
                                layout="fullWidth" />
                        </Box>
                    </Container>
                </Section>

                <Element name="work">
                    <Section bg="light" css={` padding-bottom: 15%; `} className="px-lg-4 pt-lg-5">
                        <Container fluid>
                            <MasonryStyled
                                breakpointCols={breakpointColumnsObj}
                                columnClassName="masonry-grid_column">

                                <div>
                                    <ResponsiveEmbed
                                        allowFullScreen
                                        src="https://www.youtube.com/embed/vg8rKH5_1zw"
                                        ratio={'16:9'} />
                                </div>

                                <div>
                                    <ResponsiveEmbed
                                        allowFullScreen
                                        src="https://www.youtube.com/embed/E5EBHlx9xIc"
                                        ratio={'16:9'} />
                                </div>

                                <div>
                                    <ResponsiveEmbed
                                        allowFullScreen
                                        src="https://www.youtube.com/embed/G3dBBHFkdcE"
                                        ratio={'16:9'} />
                                </div>

                                <div>
                                    <ResponsiveEmbed
                                        allowFullScreen
                                        src="https://www.youtube.com/embed/koALrbhMK_E"
                                        ratio={'16:9'} />
                                </div>

                            </MasonryStyled>
                        </Container>
                    </Section>
                </Element>

                <Section bg="dark">
                    <Container>
                        <Row css={` margin-top: -20% !important; `}>
                            <Col xs="12" className="mb-5">
                                <StaticImage
                                    src="../assets/image/work/auclair.jpg"
                                    alt="Auclair Cover"
                                    layout="fullWidth" />
                            </Col>
                        </Row>
                        <div className="text-center mt-lg-5">
                            <Text variant="tag" className="mb-1" color="lightShade">
                                Next Project
                            </Text>
                            <Link to="/canadian-thumbs-up">
                                <Button
                                    arrowRight
                                    className="border-0 bg-transparent shadow-none text-capitalize py-3"
                                    css={` font-weight: 700 font-size: 1.5rem; letter-spacing: -1.2px; line-height: 1.375; @media ${device.md} { font-size: 2rem; } `}>
                                    Canadian Thumbs Up
                                </Button>
                            </Link>
                        </div>
                    </Container>
                </Section>
            </PageWrapper>
        </React.Fragment>
    );
};
export default TRA;
